// Masthead
//
// Super small header above the content for site name and short description.

.masthead {
  padding-top: 2rem;
  // padding-bottom: 1rem;
  margin-bottom: 2rem;
  a {
    color: black;
  }
}
