.language-ruby .highlight {
  // background-color: $gray-1;
}

nav > ul {
  list-style-type: none;
  padding: 0;
}

iframe {
    width:100%;
    max-height:100%;
    height: 24rem;
}

ul {
  list-style: square;
}

.post > *:last-child {
  margin-bottom: $post-spacing;
}

nav {
  a {
    color: black;
    text-decoration: underline;
  }
}

.pagination {
  a {
    color: black;
  }
}

article iframe {
  margin-bottom: 1rem;
}
