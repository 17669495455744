// Pagination
//
// Super lightweight (HTML-wise) blog pagination. `span`s are provide for when
// there are no more previous or next posts to show.

.pagination {
  font-family: $title-font-family;
  overflow: hidden; // clearfix
  margin: 0;
  margin-bottom: $post-spacing;
  color: #ccc;
  text-align: center;

  a {
    text-decoration: underline;
  }
}

// Pagination items can be `span`s or `a`s
.pagination-item {
  display: block;
  // padding: 1rem;
  // border: solid #eee;
  // border-width: 1px 0;

  &:first-child {
    // margin-bottom: -1px;
  }
}

// Only provide a hover state for linked pagination items
a.pagination-item:hover {
  // background-color: #f5f5f5;
}

@media (min-width: 30em) {
  .pagination {
    // margin: 3rem 0;
  }

  .pagination-item {
    float: left;
    width: 50%;
    // border-width: 1px;

    &:first-child {
      margin-bottom: 0;
    }
    &:last-child {
      // margin-left: -1px;
    }
  }
}
